import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "AlfredApp Quick Productivity Hack",
  "author": "tzookb",
  "type": "post",
  "date": "2018-10-14T15:55:17.000Z",
  "url": "/2018/10/alfredapp-quick-productivity-hack/",
  "categories": ["Productivity"],
  "tags": ["apps", "hacks", "productivity"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hey everyone 🙂`}</p>
    <p>{`Wanted to share a super nice tool Im using.`}</p>
    <p>{`I just came back from my company retreat and I have noticed most of my fellow devs dont even know this app let alone use this cool trick that SAVES YOU TIME.`}</p>
    <p>{`The app is: `}<a style={{
        "backgroundColor": "#ffffff"
      }} href="https://www.alfredapp.com/" target="_blank" rel="noopener">{`AlfredApp`}</a></p>
    <p>{`It replace the Mac OS search box with its own.`}</p>
    <p>{`The app search box is SUPER QUICK!`}</p>
    <p>{`but Im not referring to that now`}{`…`}</p>
    <p>{`You can create super cool search queries that will open your favorite sites or whatever sites you use daily`}{`…`}</p>
    <p>{`github, stackoverflow, jira, assembla or even twitter feed.`}</p>
    <p>{`Check it out in the video 🙂`}</p>
    <iframe title="Productivity trick with Alfred App" width="800" height="600" src="https://www.youtube.com/embed/wtsmJYfoLxc?feature=oembed" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      